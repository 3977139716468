import React, { useState } from "react"
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { FaTimes } from "react-icons/fa";
import { BsChatRightDots } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { Block } from 'notiflix/build/notiflix-block-aio';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import parse from 'html-react-parser';
import { IconButton, Paper, Box } from '@mui/material';




function App() {
  const [opciones, setOpciones] = useState(true);
  const [showFormulario, setShowFormulario] = useState(false);
  const [formularioEnviado, setFormularioEnviado] = useState(false);
  const [formulario, setFormulario] = useState({
    nombre: "",
    rut: "",
    correo: "",
    telefono: "",
    requerimiento: "",
    observacion: ""
  })
  const [resultado, setResultado] = useState("");

  const [nombreError, setNombreError] = useState({
    value: false,
    mensaje: ""
  });

  const [rutError, setRutError] = useState({
    value: false,
    mensaje: ""
  });

  const [correoError, setCorreoError] = useState({
    value: false,
    mensaje: ""
  });

  const [telefonoError, setTelefonoError] = useState({
    value: false,
    mensaje: ""
  });

  const [requerimientoError, setRequerimientoError] = useState({
    value: false,
    mensaje: ""
  });

  const [observacionError, setObservacionError] = useState({
    value: false,
    mensaje: ""
  });

  const handleActualizarFormulario = (valor, key) => {

    if (key === "nombre") {
      setNombreError({ value: false, mensaje: "" });
    }

    if (key === 'nombre' || key === 'correo' || key === "rut") {
      valor = valor.toUpperCase();
    }

    if (key === "rut") {
      setRutError({ value: false, mensaje: "" });
      if (valor.length != 1) {
        valor = formateaRut(valor);
      }
    }

    if (key === "correo") {
      setCorreoError({ value: false, mensaje: "" });
    }

    if (key === "telefono") {
      valor = valor.replace(/[^0-9]/g, '');
      valor = valor.substring(0, 9);
      setTelefonoError({ value: false, mensaje: "" });
    }

    if (key === "requerimiento") {
      setRequerimientoError({ value: false, mensaje: "" });
    }

    setFormulario(prevState => ({
      ...prevState,
      [key]: valor
    }));
  };

  const handleSubmit = (e) => {
    var validacion = true;
    e.preventDefault();

    if (formulario.nombre === "") {
      setNombreError({ value: true, mensaje: "Este campo es requerido" });
      validacion = false;
    }

    if (formulario.rut === "") {
      setRutError({ value: true, mensaje: "Este campo es requerido" });
      validacion = false;
    } else {
      if (formulario.rut.length < 8) {
        setRutError({ value: true, mensaje: "El R.U.N ingresado es incorrecto" });
        validacion = false;
      } else {
        var validaRut = Fn.validaRut(formulario.rut);
        if (!validaRut) {
          setRutError({ value: true, mensaje: "El R.U.N ingresado es incorrecto" });
          validacion = false;
        }
      }
    }

    if (formulario.correo === "") {
      setCorreoError({ value: true, mensaje: "Este campo es requerido" });
      validacion = false;
    } else {
      var patron = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!patron.test(formulario.correo)) {
        setCorreoError({ value: true, mensaje: "Ingrese un correo electrónico válido." });
        validacion = false;
      }
    }

    if (formulario.telefono === "") {
      setTelefonoError({ value: true, mensaje: "Este campo es requerido" });
      validacion = false;
    }

    if (formulario.requerimiento === "") {
      setRequerimientoError({ value: true, mensaje: "Este campo es requerido" });
      validacion = false;
    }

    if (formulario.observacion === "") {
      setObservacionError({ value: true, mensaje: "Este campo es requerido" });
      validacion = false;
    }

    if (validacion) {
      const myHeaders = new Headers();
      myHeaders.append("x-api-key", "khyZfbv3oUDg7dgylVut3HJiDbqOFrc6QjgslpX9");
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "Nombre": formulario.nombre,
        "Rut": formulario.rut,
        "Correo": formulario.correo,
        "Requerimiento": formulario.requerimiento,
        "Observacion": formulario.observacion,
        "Telefono": formulario.telefono,
        "Ambiente": "PROD"
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      Block.circle(".cn-formulario", { zindex: 100003 });
      fetch("https://8pvcu80e3d.execute-api.us-east-1.amazonaws.com/PRODUCCION/main", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log((result.Result));
          setResultado(result.Result);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          Block.remove('.cn-formulario');
          setFormularioEnviado(true);
        });
    }
  }

  function formateaRut(rut) {

    var actual = rut.replace(/^0+/, "");

    if (actual != '' && actual.length > 1) {
      var sinPuntos = actual.replace(/\./g, "");
      var actualLimpio = sinPuntos.replace(/-/g, "");
      var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
      var rutPuntos = "";
      var i = 0;
      var j = 1;
      for (i = inicio.length - 1; i >= 0; i--) {
        var letra = inicio.charAt(i);
        rutPuntos = letra + rutPuntos;
        if (j % 3 == 0 && j <= inicio.length - 1) {
          rutPuntos = "." + rutPuntos;
        }
        j++;
      }
      var dv = actualLimpio.substring(actualLimpio.length - 1);
      rutPuntos = rutPuntos + "-" + dv;
    }
    return rutPuntos;
  }

  var Fn = {
    // Valida el rut con su cadena completa "XXXXXXXX-X"
    validaRut: function (rutCompleto) {
      rutCompleto = rutCompleto.replaceAll(".", "");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
        return false;
      var tmp = rutCompleto.split('-');
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == 'K') digv = 'k';
      return (Fn.dv(rut) == digv);
    },
    dv: function (T) {
      var M = 0, S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
      return S ? S - 1 : 'k';
    }
  }

  const loadWsp = () => {
    window.location.replace("https://api.whatsapp.com/send/?phone=56992510055&text=%C2%A1Hola+Reale+Seguros%21&type=phone_number&app_absent=0");
  }

  const actions = [
    { icon: <FaWhatsapp size={25} />, name: 'Chatea con nosotros', execute: () => loadWsp() },
    { icon: <BsChatRightDots size={25} />, name: 'Te contactamos', execute: () => setShowFormulario(true) },
  ];



  return (
    <React.Fragment>
      {
        !showFormulario &&
        <div>
          {
            opciones &&
            <Paper className='cn-opciones'>
              <Box className='cn-option' onClick={() => loadWsp()}>
                <IconButton>
                  <FaWhatsapp></FaWhatsapp>
                </IconButton>
                <span className='cn-option-text'>Chatea con nosotros</span>
              </Box>
              <Box className='cn-option' onClick={() => setShowFormulario(true)}>
                <IconButton>
                  <BsChatRightDots></BsChatRightDots>
                </IconButton>
                <span className='cn-option-text'>Te contactamos</span>
              </Box>
            </Paper>
          }
          <Button sx={{zIndex: '100000'}} onClick={() => setOpciones(!opciones)} variant="contained" className='cn-btn-init' startIcon={<PermPhoneMsgIcon />}>
            Contacto
          </Button>
          {
            /*
            
              <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 999999 }}
            icon={<SpeedDialIcon />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                size = ""
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.execute}
              />
            ))}
          </SpeedDial>
            */
          }

        </div>
      }

      {showFormulario &&
        <div className="cn-formulario">
          <div className="cn-boton-cerrar" onClick={() => { setShowFormulario(false); setFormularioEnviado(false) }}>
            <FaTimes />
          </div>
          {
            formularioEnviado ?
              <div className='cn-respuesta-formulario'>
                {typeof(resultado) == 'string' && resultado != "" &&
                  parse(resultado)
                }
              </div> :
              <React.Fragment>
                <div className='cn-titulo'>¿Te contactamos luego?</div>
                <div className="cn-subtitulo">Completa tus datos y te devolvemos la llamada</div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="cn-form-group">
                    <TextField sx={{ input: { fontSize: "14px" } , label: {fontSize: "14px"}}} error={nombreError.value} helperText={nombreError.mensaje} fullWidth size='small' id="outlined-basic" label="Nombre" variant="outlined" onChange={(e) => handleActualizarFormulario(e.target.value, "nombre")} value={formulario.nombre} />
                  </div>
                  <div className="cn-form-group">
                    <TextField sx={{ input: { fontSize: "14px" } , label: {fontSize: "14px"}}} className='cn-textfield' error={rutError.value} helperText={rutError.mensaje} fullWidth size='small' id="outlined-basic" label="R.U.N" variant="outlined" onChange={(e) => handleActualizarFormulario(e.target.value, "rut")} value={formulario.rut} />
                  </div>
                  <div className="cn-form-group">
                    <TextField sx={{ input: { fontSize: "14px" } , label: {fontSize: "14px"}}} className='cn-textfield' error={correoError.value} helperText={correoError.mensaje} fullWidth size='small' id="outlined-basic" label="Correo" variant="outlined" onChange={(e) => handleActualizarFormulario(e.target.value, "correo")} value={formulario.correo} />
                  </div>
                  <div className="cn-form-group">
                    <TextField sx={{ input: { fontSize: "14px" } , label: {fontSize: "14px"}}} className='cn-textfield' error={telefonoError.value} helperText={telefonoError.mensaje} fullWidth size='small' id="outlined-basic" label="Teléfono" variant="outlined" onChange={(e) => handleActualizarFormulario(e.target.value, "telefono")} value={formulario.telefono} />
                  </div>
                  <div className="cn-form-group">
                    <TextField
                      sx={{ input: { fontSize: "14px" } , label: {fontSize: "14px"}}}
                      select
                      id="demo-simple-select"
                      label="Requerimiento"
                      style={{ zIndex: 100002 }}
                      fullWidth
                      size='small'
                      value={formulario.requerimiento}
                      onChange={(e) => handleActualizarFormulario(e.target.value, "requerimiento")}
                      error={requerimientoError.value} helperText={requerimientoError.mensaje}
                    >
                      <MenuItem value={"ASISTENCIA"}>ASISTENCIA</MenuItem>
                      <MenuItem value={"INFORMACION"}>INFORMACIÓN</MenuItem>
                      <MenuItem value={"INSPECCIONES"}>INSPECCIONES</MenuItem>
                      <MenuItem value={"GPS"}>GPS</MenuItem>
                      <MenuItem value={"PORTAL CLIENTE"}>PORTAL CLIENTE</MenuItem>
                      <MenuItem value={"SINIESTRO"}>SINIESTRO</MenuItem>
                    </TextField>

                  </div>
                  <div className="cn-form-group">
                    <TextField
                      sx={{ input: { fontSize: "14px" } , label: {fontSize: "14px"}}}
                      inputProps={{
                        maxLength: 500,
                      }} multiline rows={3}
                      fullWidth size='small'
                      id="outlined-basic" label="Observación"
                      variant="outlined"
                      onChange={(e) => handleActualizarFormulario(e.target.value, "observacion")}
                      value={formulario.observacion}
                      error={observacionError.value} helperText={observacionError.mensaje}
                    />

                  </div>
                  <div className="cn-form-group">
                    <Button style={{ fontSize: '14px' }} type="submit" variant="contained">Enviar</Button>
                  </div>
                </form>
              </React.Fragment>
          }

        </div>
      }
    </React.Fragment>

  );
}

export default App;
